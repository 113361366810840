import React from 'react';
import Sidebar from './Sidebar';
import './Whatsapp.css';
import CampaignForm from './CampaignForm';
const Whatsapp = () => {
    return (
        <>
            <Sidebar />
            <CampaignForm />
        </>
    );
};
export default Whatsapp;