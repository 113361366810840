// import React from 'react'; // Import React
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import Router components
// import Home from './components/Home.jsx'; // Import Home component
// import NewMobilepage from './components/NewMobilepage'; // Import NewMobilepage component
// import Whatsapp from './components/Whatsapp.jsx';
// import Templates from './components/Templates.jsx';
// import Anantweb from './components/Anantweb.jsx';
// import Login from './components/Login';        // Import Login component

// const App = () => {
//     return (
//         <Router>
//             <Routes>
//                 <Route path="/Home" element={<Home />} /> {/* Route for Home */}
//                 <Route path="/new-mobile" element={<NewMobilepage />} /> {/* Route for NewMobilepage */}
//                 <Route path="/whatsapp" element={<Whatsapp />} />
//                 <Route path="/Home" element={<Home />} />
//                 <Route path="/Templates" element={<Templates />} />
//                 <Route path="/" element={<Anantweb />} />
//                 <Route path="/login" element={<Login />} />  {/* Route for /login */}
//             </Routes>
//         </Router>
//     );
// };
// export default App; // Export the App component


import React, { useEffect } from 'react'; // Import React
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'; // Import Router components
import Home from './components/Home.jsx'; // Import Home component
import NewMobilepage from './components/NewMobilepage'; // Import NewMobilepage component
import Whatsapp from './components/Whatsapp.jsx';
import Templates from './components/Templates.jsx';
import Anantweb from './components/Anantweb.jsx';
import Login from './components/Login'; // Import Login component
//import Webhook from './components/Webhook.jsx'; // Import Login component
import LabourWk from './components/LabourWk.jsx'; // Import Login component

// PrivateRoute component to check if the user is logged in
const PrivateRoute = ({ element }) => {
    const navigate = useNavigate();
    useEffect(() => {
        // Check if the user is logged in
        const isLoggedIn = localStorage.getItem('isLoggedIn');
        if (!isLoggedIn) {
            navigate('/login'); // Redirect to login page if not logged in
        }
    }, [navigate]);

    return element; // Return the element if the user is logged in
};
const App = () => {
    return (
        <Router>
            <Routes>
                {/* Define public routes */}
                <Route path="/" element={<Anantweb />} /> {/* This is your landing page, typically public */}
                <Route path="/anantwebapp" element={<Anantweb />} />
                <Route path="/login" element={<Login />} /> {/* Login page */}

                {/* Define protected routes */}
                <Route path="/Home" element={<PrivateRoute element={<Home />} />} />
                <Route path="/new-mobile" element={<PrivateRoute element={<NewMobilepage />} />} />
                <Route path="/whatsapp" element={<PrivateRoute element={<Whatsapp />} />} />
                <Route path="/Templates" element={<PrivateRoute element={<Templates />} />} />
                <Route path="/labour-wk" element={<PrivateRoute element={<LabourWk />} />} />
            </Routes>
        </Router>
    );
};

export default App; // Export the App component
