import React, { useState } from 'react'; // Import React and useState hook for managing state
import { db } from '../firebaseStore'; // Import Firestore instance from firebaseStore
import { doc, setDoc, serverTimestamp, getDoc } from 'firebase/firestore'; // Firestore functions for document manipulation
import './NewMobilepage.css'; // Import CSS for styling
import Sidebar from './Sidebar'; // Import Sidebar component for layout

const NewMobilepage = () => {
    // Define the formData state variable, which will hold the form input values
    // Initialize it with empty strings for the text fields and 'sn' for 'po'
    const [formData, setFormData] = useState({
        name: '',  // User's name
        mob: '',   // Mobile number
        com: '',   // Comment
        msgId: '', // Message ID
        msg: '',   // Message content
        mb: '',    // Static value for 'mb'
        po: 'sn'   // Static value for 'po'
    });

    // State for managing the success message after form submission
    const [successMessage, setSuccessMessage] = useState('');

    // Function to handle changes in the input fields
    const handleChange = (e) => {
        // Update the formData state with the new value based on the input's name attribute
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Function to handle the form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior (page reload)

        // Firestore document references for mobile data ('mobv') and message data ('msgg')
        const mobDocRef = doc(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'mobv', formData.mob);
        const msgDocRef = doc(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'msgg', formData.msgId);

        try {
            // Check if the 'mobv' document exists in Firestore
            const mobDoc = await getDoc(mobDocRef);
            
            if (mobDoc.exists()) {
                // If the document exists, increment the 'un' field and update the timestamp
                await setDoc(mobDocRef, {
                    un: mobDoc.data().un + 1, // Increment the 'un' field by 1
                    dt: serverTimestamp() // Update the timestamp using Firestore's server timestamp
                }, { merge: true }); // Merge to avoid overwriting existing data
            } else {
                // If the document doesn't exist, create a new 'mobv' document with initial data
                await setDoc(mobDocRef, {
                    nm: formData.name, // Add the user's name
                    mb: formData.mob,   // Add the mobile number
                    cm: formData.com,   // Add the comment
                    un: 0,               // Initialize 'un' as 1 for new entries
                    dt: serverTimestamp() // Add the timestamp
                });
            }

            // Check if the 'msgg' document exists in Firestore
            const msgDoc = await getDoc(msgDocRef);
            if (msgDoc.exists()) {
                // If the document exists, just update the timestamp
                await setDoc(msgDocRef, {
                    dt: serverTimestamp() // Update the timestamp
                }, { merge: true });
            } else {
                // If the document doesn't exist, create a new 'msgg' document
                await setDoc(msgDocRef, {
                    com: formData.com,  // Add the comment
                    dt: serverTimestamp(), // Add the timestamp
                    mb: formData.mob,   // Add the 'mob' value to 'mb' field in 'msgg'
                    msg: formData.msg,  // Add the message content
                    po: formData.po,    // Add the 'po' field
                });
            }

            // Set success message after data is added
            setSuccessMessage('Data added successfully!');

            // Reset the form fields to empty after successful submission
            setFormData({ name: '', mob: '', com: '', msgId: '', msg: '', mb: '', po: 'sn' });

        } catch (error) {
            // Log any errors that occur during Firestore operations
            console.error('Error adding document: ', error);
        }
    };

    return (
        <>
            <Sidebar />
            <div className="new_mob_outer_div">
                {/* Form for adding mobile data */}
                <form className="mobform" onSubmit={handleSubmit}>
                    {/* Input for the user's name */}
                    <input
                        className="mobinput"
                        type="text"
                        name="name" // Name attribute to match the state key
                        value={formData.name} // Bind input value to formData
                        onChange={handleChange} // Handle input change
                        placeholder="Name" // Placeholder for input field
                        required // Mark this field as required
                    />

                    {/* Input for mobile number */}
                    <input
                        className="mobinput"
                        type="text"
                        name="mob" // Name attribute for mobile number
                        value={formData.mob} // Bind to formData state
                        onChange={handleChange} // Update formData when changed
                        placeholder="Mobile"
                        required
                    />

                    {/* Input for comment */}
                    <input
                        className="mobinput"
                        type="text"
                        name="com" // Name attribute for comment
                        value={formData.com} // Bind to formData state
                        onChange={handleChange}
                        placeholder="Comment"
                        required
                    />

                    {/* Input for message ID */}
                    <input
                        className="mobinput"
                        type="text"
                        name="msgId" // Name attribute for message ID
                        value={formData.msgId} // Bind to formData state
                        onChange={handleChange}
                        placeholder="Message ID"
                        required
                    />

                    {/* Textarea for message content */}
                    <textarea
                        className="mobinput"
                        name="msg" // Name attribute for message content
                        value={formData.msg} // Bind to formData state
                        onChange={handleChange}
                        placeholder="Write message"
                        required
                    />

                    {/* Submit button to add data */}
                    <button className="mobbutton" type="submit">Add Data</button>
                </form>

                {/* Display success message if the data was added successfully */}
                {successMessage && <p className="mobp">{successMessage}</p>}
            </div>
        </>
    );
};

export default NewMobilepage; // Export the component for use in other parts of the app
