import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './Sidebar.css';

const Sidebar = () => {
    const navigate = useNavigate(); // Initialize useNavigate

    const handleButtonClick = (route) => {
        navigate(route); // Navigate to the specified route
    };

    // Sign out handler
    const handleSignOut = () => {
        localStorage.removeItem('isLoggedIn'); // Remove the login state from localStorage
        navigate('/'); // Navigate to the login page
    };

    return (
        <>

        <div className='outer_div_sidebar'>
            <div className='outer_div_logo'>
            <img src='https://anant14.in/assets/logo.png' alt='logo' />
            </div>
            <div className="sidebar">
            <button className="sidebar-item" onClick={() => handleButtonClick('/anantwebapp')}>Home</button>
                <button className="sidebar-item" onClick={() => handleButtonClick('/new-mobile')}>New Mobile</button>
                <button className="sidebar-item" onClick={() => handleButtonClick('/Whatsapp')}>Bulk Whatsapp</button>
                <button className="sidebar-item" onClick={() => handleButtonClick('/Home')}>Chats</button>
                <button className="sidebar-item" onClick={() => handleButtonClick('/Templates')}>Template</button>
                <button className="sidebar-item" onClick={() => handleButtonClick('/receipt')}>Settings</button>
                <button className="sidebar-item" onClick={() => handleButtonClick('/barcode')}>Barcode</button>
                <button className="sidebar-item" onClick={() => handleButtonClick('/labour-wk')}>Labour Wk</button>
                <button className="sidebar-item" onClick={() => handleButtonClick('/display')}>Display</button>
                <button className="sidebar-item" onClick={handleSignOut}>Sign Out</button> {/* Sign Out Button */}
            </div>
        </div>
        </>
    );
};
export default Sidebar;