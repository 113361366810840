// import React, { useState } from 'react';
// import './Login.css';  // Import the CSS file
// import { db } from '../firebaseStore'; // Import the Firestore database instance
// import { doc, getDoc } from 'firebase/firestore'; // Import Firestore functions for document manipulation
// import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

// const Login = () => {
//     const [mobile, setmobile] = useState('');
//     const [password, setPassword] = useState('');
//     const navigate = useNavigate();  // Hook used at the top level

//     // Handle form submit
//     const handleSubmit = async (e) => {
//         e.preventDefault(); // Prevent the default form submission behavior
        
//         // Validation logic before proceeding
//         if (mobile && password) {
//             await loginDoc(); // Call the loginDoc function to check credentials
//         } else {
//             console.log("Please enter both mobile number and password.");
//         }
//     };

//     // Reference to the specific document in Firestore
//     const loginDocRef = doc(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451');

//     // Login function to validate credentials
//     const loginDoc = async () => {
//         try {
//             const docSnap = await getDoc(loginDocRef);
//             if (docSnap.exists()) {
//                 // Check if the password matches the one in Firestore
//                 if (docSnap.data().pass === password) {
//                     // Navigate to the '/Home' page if password matches
//                     navigate('/Home');
//                 } else {
//                     console.log("Incorrect password!");
//                 }
//             } else {
//                 console.log("No such document!");
//             }
//         } catch (error) {
//             console.log("Error getting document:", error);
//         }
//     };

//     return (
//         <div className="login-div">
//             <form onSubmit={handleSubmit}>
//                 <h2>Login</h2>

//                 {/* Phone Number Input */}
//                 <div>
//                     <label>Phone Number</label>
//                     <input
//                         type="text"
//                         value={mobile}
//                         onChange={(e) => setmobile(e.target.value)}
//                         placeholder="Enter your phone number"
//                         required
//                     />
//                 </div>

//                 {/* Password Input */}
//                 <div>
//                     <label>Password</label>
//                     <input
//                         type="password"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         placeholder="Enter your password"
//                         required
//                     />
//                 </div>

//                 {/* Submit Button */}
//                 <button type="submit">Login</button>
//             </form>
//         </div>
//     );
// };
// export default Login;

import React, { useState, useEffect } from 'react';
import './Login.css'; // Import the CSS file
import { db } from '../firebaseStore'; // Import the Firestore database instance
import { doc, getDoc } from 'firebase/firestore'; // Import Firestore functions for document manipulation
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

const Login = () => {
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // State to manage the error message
    const navigate = useNavigate(); // Hook used at the top level

    // Check if the user is already logged in
    useEffect(() => {
        const isLoggedIn = localStorage.getItem('isLoggedIn');
        if (isLoggedIn === 'true') {
            navigate('/Home'); // Redirect to Home if already logged in
        }
    }, [navigate]);

    // Handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        setErrorMessage(''); // Clear any previous error message

        // Validation logic before proceeding
        if (mobile && password) {
            await loginDoc(); // Call the loginDoc function to check credentials
        } else {
            setErrorMessage('Please enter both mobile number and password.');
        }
    };

    // Login function to validate credentials
    const loginDoc = async () => {
        const loginDocRef = doc(db, 'WhatsApp', 'My Mobile', 'my_mobile', mobile);
        try {
            const docSnap = await getDoc(loginDocRef);
            if (docSnap.exists()) {
                // Check if the password matches the one in Firestore
                if (docSnap.data().pass === password) {
                    // Store the login state in localStorage
                    localStorage.setItem('isLoggedIn', 'true');
                    // Navigate to the '/Home' page if password matches
                    navigate('/Home');
                } else {
                    setErrorMessage('Password is incorrect.'); // Set error message for incorrect password
                }
            } else {
                setErrorMessage('No such user found.'); // Set error message for no user
            }
        } catch (error) {
            setErrorMessage('Error occurred while logging in.'); // Set error message for Firestore errors
        }
    };

    return (
        <div className="login-div">
            <form onSubmit={handleSubmit}>
                <h2>Login</h2>

                {/* Phone Number Input */}
                <div>
                    <label>Phone Number</label>
                    <input
                        type="text"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        placeholder="Enter your phone number"
                        required
                    />
                </div>

                {/* Password Input */}
                <div>
                    <label>Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter your password"
                        required
                    />
                    {/* Conditional rendering of the error message */}
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                </div>

                {/* Submit Button */}
                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default Login;
