import React from 'react'
import { collection, getDocs, setDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseStore';

const saveCampaignData = async (messagee,docc) => {
    try {
        const campaignData = {
            temp: messagee,
            dt: new Date(),
            mb: [1,2,5],
        };
        console.log('Campaign Data:', campaignData);
        const campaignRef = doc(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'compaign', docc);
        await setDoc(campaignRef, campaignData);
        alert('Campaign data saved successfully!');
    } catch (error) {
        console.error('Error saving campaign data:', error);
        alert('Error saving campaign data: ' + error.message);
    }
};
const LabourWk = () => {
    console.log("LabourWk page");
    saveCampaignData('aaaaaa','bbbbbb');
    return (
        <>
            <div className='main-div'>
                <h1>Ashish</h1>
            </div>
        </>
    )
}

export default LabourWk

