import React from "react"; // Import React library
import "./Widget.scss"; // Import SCSS styles for the Widget component

// The Widget component displays information about an item
const Widget = ({ name, description, pcs, quality, weight, createdAt, onMessageSend }) => {
    // Get today's date
    const today = new Date();
    // Create a Date object from the createdAt prop
    const createdDate = new Date(createdAt);
    // Check if the created date is today
    const isToday = createdDate.toDateString() === today.toDateString();

    // Format the date based on whether it's today or not
    const displayDate = isToday
        ? createdDate.toLocaleTimeString('en-US', { timeZone: 'Asia/Kolkata' }) // Display time for today
        : createdDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }); // Display date for other days
    // Handle the click event to send item data to the parent component
    const handleClick = () => {
        onMessageSend({ name, description, pcs, quality, weight }); // Send item data on click
    };

    return (
        <div className='widget-container' onClick={handleClick}> {/* Container for the widget */}
            <div className="widget"> {/* Main widget div */}
                <div className="left"> {/* Left section for quality and name */}
                    <span className="title">{quality}</span> {/* Display the quality */}
                    <div className="details">
                        <p className="percentage positive">{name}</p> {/* Display the name */}
                    </div>
                </div>
                <div className="right"> {/* Right section for date and pieces */}
                    <div className="date">
                        <p>{displayDate}</p> {/* Display the formatted date */}
                    </div>
                    <p className="pieces">{pcs}</p> {/* Display the number of pieces */}
                </div>
            </div>
        </div>
    );
};
export default Widget; // Export the Widget component


// import "./Widget.scss"; // Import SCSS styles
// import React from 'react';

// // The Widget component displays information about an item
// const Widget = ({  createdAt,un, nm, mb, cm, dt, onMessageSend }) => {
//     const today = new Date();
//     const createdDate = new Date(dt);
//     const isToday = createdDate.toDateString() === today.toDateString();
//     const displayDate = isToday
//         ? createdDate.toLocaleTimeString('en-US', { timeZone: 'Asia/Kolkata' })
//         : createdDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });


//     const handleClick = () => {
//         onMessageSend({ nm, un, mb, cm, dt }); // Send item data on click
//     };

//     return (
//         <div className='widget-container' onClick={handleClick}>
//             <div className="widget">
//                 <div className="left">
//                     <span className="title">{nm}</span>
//                     <div className="details">
//                         <p className="percentage positive">{mb}</p>
//                     </div>
//                 </div>
//                 <div className="right">
//                     <div className="date">
//                         <p>{displayDate}</p> {/* Display the formatted date */}
//                     </div>
//                     <p className="pieces">{un}</p>
//                 </div>
//             </div>
//         </div>
//     );
// };
// export default Widget;
