// Anantweb.js
import React from 'react';
import './Anantweb.css';
import 'bootstrap/dist/css/bootstrap.css';  // Import bootstrap CSS for styling
import { Link as RouterLink } from 'react-router-dom';  // Import RouterLink for navigation

const Anantweb = () => {
    return (
        <>
            <div className="body-div">
                {/* Navbar */}
                <div className="custom-navbar">
                    <div className="navbar-logo">
                        <img src="https://anant14.in/assets/logo.png" alt="Logo" />
                    </div>
                    <div className="contact-number">
                        <a href="tel:+91-9399870050">+91-9399870050</a>
                    </div>
                    <ul className="navbar-links">
                        {/* Solutions Dropdown */}
                        <li className="dropdown">
                            <a href="#">Solutions</a>
                            <ul className="dropdown-menu">
                                <li><a href="#whatsapp-marketing">WhatsApp Marketing</a></li>
                                <li><a href="#Multiple-Agents">WhatsApp for Customer Service</a></li>
                                <li><a href="#Chat-bot">WhatsApp Automation</a></li>
                            </ul>
                        </li>
                        <li><a href="#">Contact Us</a></li>

                        {/* Use RouterLink to navigate to /login */}
                        <li><RouterLink to="/login">Login</RouterLink></li>
                    </ul>
                </div>

                {/* Main Content */}
                <div className="main-div">
                    <div className="content-wrapper">
                        <h1>Make Your Business Easy and More Profitable.</h1>
                        <strong><h1 className="whatsapp-text">WhatsApp Business API</h1></strong>
                        <p>Manage your Sales, Support, & Marketing conversations on WhatsApp with Vardhman Softech</p>
                        <ul className="feature-list">
                            <li>Launch Promotional Campaigns</li>
                            <li>Manage Conversations with Multiple Agents</li>
                            <li>Automate Workflow with Chatbots</li>
                        </ul>
                        <button className="book-demo-button">Book Demo</button>
                    </div>
                    <div className="image-section">
                        <img src="https://anant14.in/assets/images/homebanner.jpg" alt="Chatbot Example" />
                    </div>
                </div>

                {/* Other sections */}
                {/* WhatsApp Marketing Section */}
                <div className="marketing-content-div"  id='whatsapp-marketing'>
                    <h1>Grow your Business with Our Solutions</h1>
                    <h2>A swift overview of our standout features, where innovation meets simplicity</h2>
                </div>

                <div className="whatsapp-marketing-section">
                    <div className="marketing-content">
                        <h2>WhatsApp Marketing</h2>
                        <p>
                            If you're looking to engage with your customers efficiently, Vardhman Softech's WhatsApp
                            Broadcast feature is a powerful tool. You can make offer announcements, share product
                            recommendations, and send purchase reminders all in one go. This feature helps businesses
                            connect with a large and diverse audience seamlessly. It’s an excellent way to keep your
                            customers informed and engaged at scale.
                        </p>
                        <ul className="marketing-features">
                            <li>Personalized Templates with Buttons</li>
                            <li>Custom Audience and Segmentation</li>
                            <li>Advanced Analytics</li>
                        </ul>
                    </div>
                    <div className="marketing-image">
                        <img src="https://anant14.in/assets/uploads/2024/05/whatsapp-marketing-via-cunnekt.png" />
                    </div>
                </div>
                {/* Multi-agent Customer Support Section */}
                <div className='customer-support-section' id='Multiple-Agents'>
                    <div className="customer-support">
                        <h2>Multi-agent Customer Support</h2>
                        <p>You can elevate your customer support to new heights. Here’s how:</p>
                        <ul>
                            <li><b>Advanced Roles and Permissions:</b> Tailor access levels for your support team, ensuring that each agent has the right tools and information needed for their role.</li>
                            <li><b>Smart Agent Routing:</b> Automatically direct customer inquiries to the most suitable agent based on their expertise and availability, optimizing response times and resolution efficiency.</li>
                            <li><b>Conversation and Agent Reports:</b> Gain valuable insights with detailed reports on conversations and agent performance, helping you monitor quality, identify trends, and make data-driven improvements.</li>
                        </ul>
                        <p>These features help you offer unparalleled support, handle multiple inquiries at once, and enhance overall customer satisfaction.</p>
                    </div>
                    <div className="customer-support-image">
                        <img src="https://anant14.in/assets/images/whatsapp-marketing.png" />
                    </div>
                </div>

                <div className="customer-support-section" id='Chat-bot'>
                    <div className="customer-support">
                        <h2><span className="highlight">Chatbot</span> and Automations</h2>
                        <p>Boost your customer engagement effortlessly with Vardhman Softech’s drag-and-drop chatbot builder. Here’s how it can transform your operations:</p>
                        <ul className="automation-features">
                            <li>
                                <strong>Lead Qualification:</strong> Automate the process of identifying and nurturing potential leads, ensuring you focus on the most promising prospects.
                            </li>
                            <li>
                                <strong>Customer Query Resolution:</strong> Provide instant answers to common questions, enhancing customer satisfaction and freeing up your team for more complex issues.
                            </li>
                            <li>
                                <strong>Appointment Booking:</strong> Simplify scheduling with automated booking options, reducing friction and improving convenience for your customers.
                            </li>
                            <li>
                                <strong>Enhanced Automation:</strong> Beyond chatbots, leverage other automation features to create a seamless customer journey, elevate your brand reputation, and drive business growth.
                            </li>
                        </ul>
                        <p>With these tools, you can deliver prompt, efficient service and build stronger, more engaging customer relationships.</p>
                    </div>
                    <div className="customer-support-image">
                        <img src="https://anant14.in/assets/images/homebanner-middle.png" />
                    </div>
                </div>
                <div className='get-demo-container'>
                    <div className='get_demo'>
                        <h3>Book a Live Demo Session with our WhatsApp Experts</h3>
                        <button className='get_link'>Book Demo →</button>
                    </div>
                </div>
                <div className='Vardhman-Softech-div'>
                    <h3>The Vardhman Softech Advantage</h3>
                </div>
                <div className='vardhman-softech-divs-container'>
                    <div className='Vardhman-Softech-div1'>
                        <img src="https://anant14.in/assets/images/_ic_1.jpg" alt="" />
                        <p><h5>Instant</h5>
                            Whatsapp Activation</p>
                    </div>
                    <div className='Vardhman-Softech-div2'>
                        <img src="https://anant14.in/assets/images/_ic_3.jpg" alt="" />
                        <p><h5>Zero</h5>
                            Conversation Markup</p>
                    </div>
                    <div className='Vardhman-Softech-div3'>
                        <img src="https://anant14.in/assets/images/_ic_6.jpg" alt="" />
                        <p><h5>24*7</h5>
                            Client Support</p>
                    </div>
                </div>
                <div className='industry-container-TEXT'>
                    <h3>One Solution, Multiple Industries</h3>
                    <p>Tailored to meet the specific needs of any industry vertical.</p>
                </div>
                <div class="industry-container">
                    <div class="industry-box">
                        <div class="icon">🚗</div>
                        <h3>Travel and Hospitality</h3>
                        <p>Address customer queries and drive sales with multi-agent CRM login. Automate booking notifications to customers via API and also gather reviews.</p>
                    </div>

                    <div class="industry-box">
                        <div class="icon">📈</div>
                        <h3>Fintech</h3>
                        <p>Educate and onboard customers directly through WhatsApp. Send transactional notifications via WhatsApp and address general queries using chatbots.</p>
                    </div>
                    <div class="industry-box">
                        <div class="icon">🍴</div>
                        <h3>Food and Restaurants</h3>
                        <p>Transform WhatsApp into your dining menu, allowing you to collect orders and payments directly through the platform. Send promotional offers to your customers and gather reviews effortlessly.</p>
                    </div>
                </div>
                <div>
                    <div class="industry-container">
                        <div class="industry-box">
                            <div class="icon">📦</div>
                            <h3>Retail</h3>
                            <p>Display products directly on WhatsApp and drive sales. Utilize Vardhman Softech APIs to send product offers, provide order updates, and much more.</p>
                        </div>
                        <div class="industry-box">
                            <div class="icon">📚</div>
                            <h3>Education</h3>
                            <p>Automate student onboarding with user-friendly chatbots. Offer support and send notifications to teachers, parents, and students simultaneously.</p>
                        </div>
                        <div class="industry-box">
                            <div class="icon">🚑</div>
                            <h3>Healthcare</h3>
                            <p>Enhance process efficiency by scheduling appointments, sending medication reminders, and providing aftercare instructions—all through WhatsApp.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="roi-section">
                        <h2>Getting started with the WhatsApp Business API can significantly enhance your business ROI</h2>
                        <div class="roi-cards-container">
                            <div class="roi-card">
                                <h3>4x</h3>
                                <p>Increase in <strong>Sales</strong></p>
                            </div>
                            <div class="roi-card">
                                <h3>50%</h3>
                                <p>Increase in <strong>cart recoveries</strong></p>
                            </div>
                            <div class="roi-card">
                                <h3>80%</h3>
                                <p>Higher <strong>engagement rate</strong></p>
                            </div>
                            <div class="roi-card">
                                <h3>50%</h3>
                                <p>Boost in <strong>new sales</strong></p>
                            </div>
                        </div>
                    </div>
                    <div class="footer-banner">
                        <img src="https://anant14.in/assets/images/footerbanner.jpg" alt="" />
                    </div>
                </div>
            </div>
        </>
    );
};
export default Anantweb;