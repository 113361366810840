// // import React, { useEffect, useState } from 'react';
// // import { collection, getDocs, doc, setDoc } from 'firebase/firestore';
// // import { db } from '../firebaseStore';
// // import * as XLSX from 'xlsx';
// // import './CampaignForm.scss';
// // import 'bootstrap/dist/css/bootstrap.css';

// // const CampaignForm = () => {
// //     const [numbers, setNumbers] = useState('');
// //     const [progress, setProgress] = useState(0);
// //     const [currentDisplay, setCurrentDisplay] = useState('');
// //     const [message, setMessage] = useState('');
// //     const [numbersArray, setNumbersArray] = useState([]);
// //     const [Exceldata, setExceldata] = useState(false);
// //     const [chatsData, setChatsData] = useState([]);
// //     const [selectedTemplate, setSelectedTemplate] = useState('');
// //     const [imageFile, setImageFile] = useState(null);
// //     const [videoFile, setVideoFile] = useState(null);
// //     const [pdfFile, setPdfFile] = useState(null);
// //     const [CompaignId, setCompaignId] = useState('');
// //     const [campaignData, setCampaignData] = useState([]);  // State to store campaign data

// //     // Fetching chat templates from Firebase
// //     const fetchChatsData = async () => {
// //         try {
// //             const querySnapshot = await getDocs(collection(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'template'));
// //             const chatsList = querySnapshot.docs.map(doc => ({
// //                 id: doc.id,
// //                 temp: doc.data().temp || '',  // Store the message template from Firebase
// //             }));
// //             setChatsData(chatsList);  // Store the fetched chat templates in the state
// //         } catch (error) {
// //             console.error('Error fetching chat data:', error);
// //         }
// //     };

// //     // Handles Excel file upload and extracts numbers from the first column
// //     const handleFileUpload = (event) => {
// //         const file = event.target.files[0];
// //         if (file && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
// //             const reader = new FileReader();
// //             reader.onload = (e) => {
// //                 const data = e.target.result;
// //                 const workbook = XLSX.read(data, { type: 'binary' });
// //                 const worksheet = workbook.Sheets[workbook.SheetNames[0]];
// //                 const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
// //                 const numbersArray = jsonData.map(row => row[0]);  // Extract phone numbers from the first column
// //                 setNumbers(numbersArray.join('\n'));  // Update the numbers field with the joined numbers
// //                 setNumbersArray(numbersArray);  // Store numbers in the state
// //             };
// //             reader.readAsBinaryString(file);
// //         } else {
// //             alert('Please upload a valid Excel file (.xlsx)');
// //         }
// //     };

// //     // Displays the numbers one by one with progress
// //     const displayDataOneByOne = () => {
// //         let index = 0;
// //         const totalNumbers = numbersArray.length;
// //         const intervalId = setInterval(() => {
// //             setCurrentDisplay(numbersArray[index]);  // Display the current number
// //             index++;
// //             const currentProgress = Math.floor((index / totalNumbers) * 100);  // Calculate the progress percentage
// //             setProgress(currentProgress);  // Update the progress bar
// //             if (index >= totalNumbers) {
// //                 clearInterval(intervalId);  // Stop the interval once all numbers are displayed
// //             }
// //         }, 1000);  // Update every second
// //     };

// //     // Save the campaign data to Firestore
// //     const saveCampaignData = async () => {
// //         try {
// //             // Create a reference to the Firestore document with the campaign name as the document ID
// //             const campaignRef = doc(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'compaign', CompaignId);

// //             // Prepare the data to be saved in Firestore
// //             const campaignData = {
// //                 temp: message,  // The message template
// //                 numbers: numbersArray,  // Array of numbers from the Excel file
// //                 dt: new Date(),  // Date when the campaign is created
// //                 mb: '9294800451',  // Mobile number (could be dynamic or retrieved from user input)
// //             };

// //             // Save the data to Firestore
// //             await setDoc(campaignRef, campaignData);

// //             alert('Campaign data saved successfully!');
// //         } catch (error) {
// //             console.error('Error saving campaign data:', error);
// //             alert('Error saving campaign data');
// //         }
// //     };

// //     // Handle template selection
// //     const handleTemplateSelect = (templateId) => {
// //         const selected = chatsData.find(template => template.id === templateId);
// //         if (selected) {
// //             setMessage(selected.temp);  // Set the message to the selected template
// //         }
// //     };

// //     // Handles file uploads for image, video, and PDF files
// //     const handleFileChange = (e, fileType) => {
// //         const file = e.target.files[0];
// //         if (file) {
// //             switch (fileType) {
// //                 case 'image':
// //                     setImageFile(file);  // Update the image file in state
// //                     break;
// //                 case 'video':
// //                     setVideoFile(file);  // Update the video file in state
// //                     break;
// //                 case 'pdf':
// //                     setPdfFile(file);    // Update the PDF file in state
// //                     break;
// //                 default:
// //                     break;
// //             }
// //         }
// //     };

// //     useEffect(() => {
// //         fetchChatsData();  // Fetch templates on component mount
// //     }, []);

// //     return (
// //         <>
// //             {/* Progress bar for Excel file upload */}
// //             {Exceldata && (
// //                 <div className="progress" role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
// //                     <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: `${progress}%` }}></div>
// //                 </div>
// //             )}

// //             {/* Main form container */}
// //             <form>
// //                 <div className="campaign-form">
// //                     <div className="header">
// //                         <h5 className='header-text11'>Campaign Name</h5>
// //                         <input
// //                             className='header-input campaign-name'
// //                             type="text"
// //                             placeholder="Campaign Name"
// //                             value={CompaignId}
// //                             onChange={(e) => setCompaignId(e.target.value)}  // Updated to use state
// //                             required
// //                         />
// //                         <h5 className='header-text'>Templates</h5>
// //                         <select
// //                             className='header-input template'
// //                             value={selectedTemplate}
// //                             onChange={(e) => {
// //                                 const templateId = e.target.value;
// //                                 setSelectedTemplate(templateId);
// //                                 handleTemplateSelect(templateId);
// //                             }}
// //                             required
// //                         >
// //                             <option value="">Select a Template</option>
// //                             {chatsData.map(Templates => (
// //                                 <option key={Templates.id} value={Templates.id}>
// //                                     {Templates.id}
// //                                 </option>
// //                             ))}
// //                         </select>
// //                     </div>

// //                     {/* Excel file upload */}
// //                     <div className='Excel-div'>
// //                         <label className='Excel-lable' htmlFor="file">Excel File</label>
// //                         <input
// //                             name="Excel File"
// //                             id="file"
// //                             style={{ display: 'none' }}
// //                             type="file"
// //                             accept=".xlsx, .xls"
// //                             onChange={handleFileUpload}
// //                             required
// //                         />
// //                     </div>

// //                     <div className="content">
// //                         <div className="left">
// //                             <label>Numbers:</label>
// //                             <textarea
// //                                 className='tera1'
// //                                 placeholder="Enter numbers"
// //                                 value={numbers}
// //                                 onChange={(e) => setNumbers(e.target.value)}
// //                                 required
// //                             />
// //                         </div>

// //                         <div className="right">
// //                             <div className='message-div'>
// //                                 {Exceldata && <h6 className='message-h6'>{currentDisplay}</h6>}
// //                                 <label>Message:</label>
// //                                 <textarea
// //                                     className='tera2'
// //                                     placeholder="Enter message"
// //                                     value={message}
// //                                     onChange={(e) => setMessage(e.target.value)}
// //                                     required
// //                                 />
// //                             </div>

// //                             <div className="uploads">
// //                                 <div>
// //                                     <label htmlFor="image-file" className="file-label">PDF (Max File Size 2 MB) :→</label>
// //                                     <input
// //                                         id="image-file"
// //                                         type="file"
// //                                         accept="image/*"
// //                                         style={{ display: 'none' }}
// //                                         onChange={(e) => handleFileChange(e, 'image')}
// //                                     />
// //                                     {imageFile && <span>{imageFile.name}</span>}
// //                                 </div>

// //                                 <div>
// //                                     <label htmlFor="video-file" className="file-label">Video Upload (Max File Size 3 MB) :→</label>
// //                                     <input
// //                                         id="video-file"
// //                                         type="file"
// //                                         accept="video/*"
// //                                         style={{ display: 'none' }}
// //                                         onChange={(e) => handleFileChange(e, 'video')}
// //                                     />
// //                                     {videoFile && <span>{videoFile.name}</span>}
// //                                 </div>

// //                                 <div>
// //                                     <label htmlFor="pdf-file" className="file-label">Image Upload (Max File Size 3 MB) :→</label>
// //                                     <input
// //                                         id="pdf-file"
// //                                         type="file"
// //                                         accept="application/pdf"
// //                                         style={{ display: 'none' }}
// //                                         onChange={(e) => handleFileChange(e, 'pdf')}
// //                                     />
// //                                     {pdfFile && <span>{pdfFile.name}</span>}
// //                                 </div>
// //                             </div>

// //                             <div className="actions">
// //                                 <button className="send-now" onClick={() => {
// //                                     displayDataOneByOne();  // Trigger the display of numbers one by one
// //                                     setExceldata(true);  // Show the progress bar
// //                                     saveCampaignData();  // Save the campaign data to Firebase
// //                                 }}>
// //                                     Send Now
// //                                 </button>
// //                             </div>
// //                         </div>
// //                     </div>
// //                 </div>
// //             </form>

// //             {/* Display fetched campaign data */}
// //             <div className="campaign-data">
// //                 <h5>Campaign Data</h5>
// //                 <ul>
// //                     {campaignData.length > 0 ? (
// //                         campaignData.map((campaign) => (
// //                             <li key={campaign.id}>
// //                                 <strong>Campaign ID:</strong> {campaign.id}<br />
// //                                 <strong>Message Template:</strong> {campaign.temp}<br />
// //                                 <strong>Date:</strong> {campaign.dt ? campaign.dt.toString() : 'N/A'}<br />
// //                                 <strong>Mobile:</strong> {campaign.mb || 'N/A'}
// //                             </li>
// //                         ))
// //                     ) : (
// //                         <p>No campaigns available.</p>
// //                     )}
// //                 </ul>
// //             </div>
// //         </>
// //     );
// // };
// // export default CampaignForm;


// import React, { useEffect, useState } from 'react';
// import { collection, getDocs, setDoc, doc } from 'firebase/firestore';
// import { db } from '../firebaseStore';
// import * as XLSX from 'xlsx';
// import './CampaignForm.scss';
// import 'bootstrap/dist/css/bootstrap.css';

// const CampaignForm = () => {
//     const [numbers, setNumbers] = useState('');
//     const [progress, setProgress] = useState(0);
//     const [currentDisplay, setCurrentDisplay] = useState('');
//     const [message, setMessage] = useState('');
//     const [numbersArray, setNumbersArray] = useState([]);
//     const [Exceldata, setExceldata] = useState(false);
//     const [chatsData, setChatsData] = useState([]);
//     const [selectedTemplate, setSelectedTemplate] = useState('');
//     const [imageFile, setImageFile] = useState(null);
//     const [videoFile, setVideoFile] = useState(null);
//     const [pdfFile, setPdfFile] = useState(null);
//     const [CompaignId, setCompaignId] = useState('');
//     const [campaignData, setCampaignData] = useState([]); // State to store campaign data

//     // Handles Excel file upload and extracts numbers from the first column
//     const handleFileUpload = (event) => {
//         const file = event.target.files[0];
//         if (file && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
//             const reader = new FileReader();
//             reader.onload = (e) => {
//                 const data = e.target.result;
//                 const workbook = XLSX.read(data, { type: 'binary' });
//                 const worksheet = workbook.Sheets[workbook.SheetNames[0]];
//                 const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
//                 const numbersArray = jsonData.map(row => row[0]); // Extract phone numbers from the first column
//                 setNumbers(numbersArray.join('\n')); // Update the numbers field with the joined numbers
//                 setNumbersArray(numbersArray); // Store numbers in the state
//             };
//             reader.readAsBinaryString(file);
//         } else {
//             alert('Please upload a valid Excel file (.xlsx)');
//         }
//     };

//     // Displays the numbers one by one with progress
//     const displayDataOneByOne = () => {
//         let index = 0;
//         const totalNumbers = numbersArray.length;
//         const intervalId = setInterval(() => {
//             setCurrentDisplay(numbersArray[index]); // Display the current number
//             index++;
//             const currentProgress = Math.floor((index / totalNumbers) * 100); // Calculate the progress percentage
//             setProgress(currentProgress); // Update the progress bar
//             if (index >= totalNumbers) {
//                 clearInterval(intervalId); // Stop the interval once all numbers are displayed
//             }
//         }, 1000); // Update every second
//     };

//     // Fetching campaign data from Firebase
//     const Compaign = async () => {
//         try {
//             const querySnapshot = await getDocs(collection(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'compaign'));
//             const campaigns = querySnapshot.docs.map(doc => ({
//                 id: doc.id,
//                 temp: doc.data().temp || '',
//                 dt: doc.data().dt?.toDate() || '',
//                 mb: doc.data().mb || '',
//             }));
//             setCampaignData(campaigns); // Store the fetched campaign data in state
//         } catch (error) {
//             console.error('Error fetching campaign data:', error);
//         }
//     };

//     useEffect(() => {
//         Compaign(); // Fetch campaign data when the component mounts
//     }, []);

//     // Fetching chat templates from Firebase
//     const fetchChatsData = async () => {
//         try {
//             const querySnapshot = await getDocs(collection(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'template'));
//             const chatsList = querySnapshot.docs.map(doc => ({
//                 id: doc.id,
//                 temp: doc.data().temp || '', // Store the message template from Firebase
//             }));
//             setChatsData(chatsList); // Store the fetched chat templates in the state
//         } catch (error) {
//             console.error('Error fetching chat data:', error);
//         }
//     };

//     const handleTemplateSelect = (templateId) => {
//         const selected = chatsData.find(template => template.id === templateId);
//         if (selected) {
//             setMessage(selected.temp); // Set the message to the selected template
//         }
//     };

//     // Handles file uploads for image, video, and PDF files
//     const handleFileChange = (e, fileType) => {
//         const file = e.target.files[0];
//         if (file) {
//             switch (fileType) {
//                 case 'image':
//                     setImageFile(file); // Update the image file in state
//                     break;
//                 case 'video':
//                     setVideoFile(file); // Update the video file in state
//                     break;
//                 case 'pdf':
//                     setPdfFile(file); // Update the PDF file in state
//                     break;
//                 default:
//                     break;
//             }
//         }
//     };

//     // Save campaign data to Firestore
//     const saveCampaignData = async () => {
//         try {
//             // Log the campaign data before saving
//             const campaignData = {
//                 temp: message, // The message template
//                 dt: new Date(), // Date when the campaign is created
//                 mb: numbersArray, // Mobile number (could be dynamic or retrieved from user input)
//             };

//             console.log('Campaign Data:', campaignData); // Log the data for debugging

//             // Ensure CompaignId is not empty
//             const campaignId = CompaignId || new Date().toISOString(); // Use current time as fallback ID
//             const campaignRef = doc(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'compaign', campaignId);

//             // Save the campaign data to Firestore
//             await setDoc(campaignRef, campaignData);

//             alert('Campaign data saved successfully!');
//         } catch (error) {
//             console.error('Error saving campaign data:', error); // Log the error
//             alert('Error saving campaign data: ' + error.message); // Show error message to the user
//         }
//     };

//     return (
//         <>
//             {/* Progress bar for Excel file upload */}
//             {Exceldata && (
//                 <div className="progress" role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
//                     <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: `${progress}%` }}></div>
//                 </div>
//             )}

//             {/* Main form container */}
//             <form>
//                 <div className="campaign-form">
//                     <div className="header">
//                         <h5 className='header-text11'>Campaign Name</h5>
//                         <input
//                             className='header-input campaign-name'
//                             type="text"
//                             placeholder="Campaign Name"
//                             value={CompaignId}
//                             onChange={(e) => setCompaignId(e.target.value)} // Updated to use state
//                             required
//                         />
//                         <h5 className='header-text'>Templates</h5>
//                         <select
//                             className='header-input template'
//                             value={selectedTemplate}
//                             onChange={(e) => {
//                                 const templateId = e.target.value;
//                                 setSelectedTemplate(templateId);
//                                 handleTemplateSelect(templateId);
//                             }}
//                             required
//                         >
//                             <option value="">Select a Template</option>
//                             {chatsData.map(Templates => (
//                                 <option key={Templates.id} value={Templates.id}>
//                                     {Templates.id}
//                                 </option>
//                             ))}
//                         </select>
//                     </div>

//                     {/* Excel file upload */}
//                     <div className='Excel-div'>
//                         <label className='Excel-lable' htmlFor="file">Excel File</label>
//                         <input
//                             name="Excel File"
//                             id="file"
//                             style={{ display: 'none' }}
//                             type="file"
//                             accept=".xlsx, .xls"
//                             onChange={handleFileUpload}
//                             required
//                         />
//                     </div>

//                     <div className="content">
//                         <div className="left">
//                             <label>Numbers:</label>
//                             <textarea
//                                 className='tera1'
//                                 placeholder="Enter numbers"
//                                 value={numbers}
//                                 onChange={(e) => setNumbers(e.target.value)}
//                                 required
//                             />
//                         </div>

//                         <div className="right">
//                             <div className='message-div'>
//                                 {Exceldata && <h6 className='message-h6'>{currentDisplay}</h6>}
//                                 <label>Message:</label>
//                                 <textarea
//                                     className='tera2'
//                                     placeholder="Enter message"
//                                     value={message}
//                                     onChange={(e) => setMessage(e.target.value)}
//                                     required
//                                 />
//                             </div>

//                             <div className="uploads">
//                                 <div>
//                                     <label htmlFor="image-file" className="file-label">PDF (Max File Size 2 MB) :→</label>
//                                     <input
//                                         id="image-file"
//                                         type="file"
//                                         accept="image/*"
//                                         style={{ display: 'none' }}
//                                         onChange={(e) => handleFileChange(e, 'image')}
//                                     />
//                                 </div>

//                                 <div>
//                                     <label htmlFor="pdf-file" className="file-label">Image (Max File Size 2 MB) :→</label>
//                                     <input
//                                         id="pdf-file"
//                                         type="file"
//                                         accept="application/pdf"
//                                         style={{ display: 'none' }}
//                                         onChange={(e) => handleFileChange(e, 'pdf')}
//                                     />
//                                 </div>
//                                 <div>
//                                     <label htmlFor="pdf-file" className="file-label">Image (Max File Size 2 MB) :→</label>
//                                     <input
//                                         id="pdf-file"
//                                         type="file"
//                                         accept="application/pdf"
//                                         style={{ display: 'none' }}
//                                         onChange={(e) => handleFileChange(e, 'video')}
//                                     />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="actions">
//                             <button className="send-now" onClick={() => {
//                                 setExceldata(true);
//                                 displayDataOneByOne();
//                                 saveCampaignData();
//                             }}>Send Now</button>
//                             <button className="check-numbers">Check Numbers</button>
//                         </div>
//                 </div>
//             </form>
//         </>
//     );
// };

// export default CampaignForm;



import React, { useEffect, useState } from 'react';
import { collection, getDocs, setDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseStore';
import * as XLSX from 'xlsx';
import './CampaignForm.scss';
import 'bootstrap/dist/css/bootstrap.css';

const CampaignForm = () => {
    const [numbers, setNumbers] = useState('');
    const [progress, setProgress] = useState(0);
    const [currentDisplay, setCurrentDisplay] = useState('');
    const [message, setMessage] = useState('');
    const [numbersArray, setNumbersArray] = useState([]);
    const [Exceldata, setExceldata] = useState(false);
    const [chatsData, setChatsData] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [pdfFile, setPdfFile] = useState(null);
    const [CompaignId, setCompaignId] = useState('');
    const [campaignData, setCampaignData] = useState([]);

    // Handles Excel file upload and extracts numbers from the first column  
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                const numbersArray = jsonData.map(row => row[0]);
                setNumbers(numbersArray.join('\n'));
                setNumbersArray(numbersArray);
            };
            reader.readAsBinaryString(file);
        } else {
            alert('Please upload a valid Excel file (.xlsx)');
        }
    };

    // Displays the numbers one by one with progress  
    const displayDataOneByOne = () => {
        let index = 0;
        const totalNumbers = numbersArray.length;
        const intervalId = setInterval(() => {
            setCurrentDisplay(numbersArray[index]);
            index++;
            const currentProgress = Math.floor((index / totalNumbers) * 100);
            setProgress(currentProgress);
            if (index >= totalNumbers) {
                clearInterval(intervalId);
            }
        }, 1000);
    };

    // Fetching campaign data from Firebase  
    const Compaign = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'compaign'));
            const campaigns = querySnapshot.docs.map(doc => ({
                id: doc.id,
                temp: doc.data().temp || '',
                dt: doc.data().dt?.toDate() || '',
                mb: doc.data().mb || '',
            }));
            setCampaignData(campaigns);
        } catch (error) {
            console.error('Error fetching campaign data:', error);
        }
    };

    useEffect(() => {
        Compaign();
    }, []);

    // Fetching chat templates from Firebase  
    const fetchChatsData = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'template'));
            const chatsList = querySnapshot.docs.map(doc => ({
                id: doc.id,
                temp: doc.data().temp || '',
            }));
            setChatsData(chatsList);
        } catch (error) {
            console.error('Error fetching chat data:', error);
        }
    };

    const handleTemplateSelect = (templateId) => {
        const selected = chatsData.find(template => template.id === templateId);
        if (selected) {
            setMessage(selected.temp);
        }
    };

    // Handles file uploads for image, video, and PDF files  
    const handleFileChange = (e, fileType) => {
        const file = e.target.files[0];
        if (file) {
            switch (fileType) {
                case 'image':
                    setImageFile(file);
                    break;
                case 'video':
                    setVideoFile(file);
                    break;
                case 'pdf':
                    setPdfFile(file);
                    break;
                default:
                    break;
            }
        }
    };

    // Save campaign data to Firestore  
    const saveCampaignData = async () => {
        try {
            const campaignData = {
                temp: message,
                dt: new Date(),
                mb: numbersArray,
            };
            console.log('Campaign Data:', campaignData);
            const campaignId = CompaignId || new Date().toISOString();
            const campaignRef = doc(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'compaign', campaignId);
            await setDoc(campaignRef, campaignData);
            alert('Campaign data saved successfully!');
        } catch (error) {
            console.error('Error saving campaign data:', error);
            alert('Error saving campaign data: ' + error.message);
        }
    };

    return (
        <>
            {/* Progress bar for Excel file upload */}
            {Exceldata && (
                <div className="progress" role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
                    <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: `${progress}%` }}></div> 
                </div>
            )}
            {/* Main form container */}
            <form>
                <div className="campaign-form">
                    <div className="header">
                        <h5 className='header-text11'>Campaign Name</h5>
                        <input
                            className='header-input campaign-name'
                            type="text"
                            placeholder="Campaign Name"
                            value={CompaignId}
                            onChange={(e) => setCompaignId(e.target.value)}
                            required
                        />
                        <h5 className='header-text'>Templates</h5>
                        <select
                            className='header-input template'
                            value={selectedTemplate}
                            onChange={(e) => {
                                const templateId = e.target.value;
                                setSelectedTemplate(templateId);
                                handleTemplateSelect(templateId);
                            }}
                            required
                        >
                            <option value="">Select a Template</option>
                            {chatsData.map(Templates => (
                                <option key={Templates.id} value={Templates.id}>
                                    {Templates.id}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* Excel file upload */}
                    <div className='Excel-div'>
                        <label className='Excel-lable' htmlFor="file">Excel File</label>
                        <input
                            name="Excel File"
                            id="file"
                            style={{ display: 'none' }}
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileUpload}
                            required
                        />
                    </div>
                    <div className="content">
                        <div className="left">
                            <label className='Excel-lable1'>Numbers:</label>
                            <textarea
                                className='tera1'
                                placeholder="Enter numbers"
                                value={numbers}
                                onChange={(e) => setNumbers(e.target.value)}
                                required
                            />
                        </div>
                        <div className="right">
                            <div className='message-div'>
                                {Exceldata && <h6 className='message-h6'>{currentDisplay}</h6>}
                                <label className='Excel-lable2'>Message:</label>
                                <textarea
                                    className='tera2'
                                    placeholder="Enter message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="uploads">
                                <div>
                                    <label htmlFor="image-file" className="file-label">PDF (Max File Size 2 MB) :→</label>
                                    <input
                                        id="image-file"
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleFileChange(e, 'image')}
                                    />
                                    {imageFile && <span>{imageFile.name}</span>}
                                </div>
                                <div>
                                    <label htmlFor="video-file" className="file-label">Video Upload (Max File Size 3 MB) :→</label>
                                    <input
                                        id="video-file"
                                        type="file"
                                        accept="video/*"
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleFileChange(e, 'video')}
                                    />
                                    {videoFile && <span>{videoFile.name}</span>}
                                </div>
                                <div>
                                    <label htmlFor="pdf-file" className="file-label">Image Upload (Max File Size 3 MB) :→</label>
                                    <input
                                        id="pdf-file"
                                        type="file"
                                        accept="application/pdf"
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleFileChange(e, 'pdf')}
                                    />
                                    {pdfFile && <span>{pdfFile.name}</span>}
                                </div>
                            </div>
                            <div className="actions">
                                <button className="send-now" onClick={() => {
                                    displayDataOneByOne();
                                    setExceldata(true);
                                    saveCampaignData();
                                }}>
                                    Send Now
                                </button>
                                <button className="check-numbers1">Check Numbers</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default CampaignForm;  



// import React, { useEffect, useState } from 'react';
// import { collection, getDocs, setDoc, doc } from 'firebase/firestore';
// import { db } from '../firebaseStore';
// import * as XLSX from 'xlsx';
// import './CampaignForm.scss';
// import 'bootstrap/dist/css/bootstrap.css';

// const CampaignForm = () => {
//     const [numbers, setNumbers] = useState('');
//     const [progress, setProgress] = useState(0);
//     const [currentDisplay, setCurrentDisplay] = useState('');
//     const [message, setMessage] = useState('');
//     const [numbersArray, setNumbersArray] = useState([]);
//     const [Exceldata, setExceldata] = useState(false);
//     const [chatsData, setChatsData] = useState([]);
//     const [selectedTemplate, setSelectedTemplate] = useState('');
//     const [imageFile, setImageFile] = useState(null);
//     const [videoFile, setVideoFile] = useState(null);
//     const [pdfFile, setPdfFile] = useState(null);
//     const [CompaignId, setCompaignId] = useState('');
//     const [campaignData, setCampaignData] = useState([]);

//     // Handles Excel file upload and extracts numbers from the first column
//     const handleFileUpload = (event) => {
//         const file = event.target.files[0];
//         if (file && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
//             const reader = new FileReader();
//             reader.onload = (e) => {
//                 const data = e.target.result;
//                 const workbook = XLSX.read(data, { type: 'binary' });
//                 const worksheet = workbook.Sheets[workbook.SheetNames[0]];
//                 const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
//                 const numbersArray = jsonData.map(row => row[0]);
//                 setNumbers(numbersArray.join('\n'));
//                 setNumbersArray(numbersArray);

//                 // Start loader and process numbers
//                 setExceldata(true);  // Show loader
//                 displayDataOneByOne();  // Start displaying numbers
//             };
//             reader.readAsBinaryString(file);
//         } else {
//             alert('Please upload a valid Excel file (.xlsx)');
//         }
//     };

//     // Displays numbers one by one with progress
//     const displayDataOneByOne = () => {
//         let index = 0;
//         const totalNumbers = numbersArray.length;
//         const intervalId = setInterval(() => {
//             setCurrentDisplay(numbersArray[index]);
//             index++;
//             const currentProgress = Math.floor((index / totalNumbers) * 100);
//             setProgress(currentProgress);  // Update progress
//             if (index >= totalNumbers) {
//                 clearInterval(intervalId);
//                 setExceldata(false);  // Hide loader when finished
//             }
//         }, 1000);
//     };

//     // Fetching campaign data from Firebase
//     const Compaign = async () => {
//         try {
//             const querySnapshot = await getDocs(collection(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'compaign'));
//             const campaigns = querySnapshot.docs.map(doc => ({
//                 id: doc.id,
//                 temp: doc.data().temp || '',
//                 dt: doc.data().dt?.toDate() || '',
//                 mb: doc.data().mb || '',
//             }));
//             setCampaignData(campaigns);
//         } catch (error) {
//             console.error('Error fetching campaign data:', error);
//         }
//     };

//     useEffect(() => {
//         Compaign();
//     }, []);

//     // Fetching chat templates from Firebase
//     const fetchChatsData = async () => {
//         try {
//             const querySnapshot = await getDocs(collection(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'template'));
//             const chatsList = querySnapshot.docs.map(doc => ({
//                 id: doc.id,
//                 temp: doc.data().temp || '',
//             }));
//             setChatsData(chatsList);
//         } catch (error) {
//             console.error('Error fetching chat data:', error);
//         }
//     };

//     useEffect(() => {
//         fetchChatsData();
//     }, []);

//     const handleTemplateSelect = (templateId) => {
//         const selected = chatsData.find(template => template.id === templateId);
//         if (selected) {
//             setMessage(selected.temp);
//         }
//     };

//     // Handles file uploads for image, video, and PDF files
//     const handleFileChange = (e, fileType) => {
//         const file = e.target.files[0];
//         if (file) {
//             switch (fileType) {
//                 case 'image':
//                     setImageFile(file);
//                     break;
//                 case 'video':
//                     setVideoFile(file);
//                     break;
//                 case 'pdf':
//                     setPdfFile(file);
//                     break;
//                 default:
//                     break;
//             }
//         }
//     };

//     // Save campaign data to Firestore
//     const saveCampaignData = async () => {
//         try {
//             const campaignData = {
//                 temp: message,
//                 dt: new Date(),
//                 mb: numbersArray,
//             };

//             const campaignId = CompaignId || new Date().toISOString();
//             const campaignRef = doc(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'compaign', campaignId);
//             await setDoc(campaignRef, campaignData);

//             alert('Campaign data saved successfully!');
//         } catch (error) {
//             console.error('Error saving campaign data:', error);
//             alert('Error saving campaign data: ' + error.message);
//         }
//     };

//     return (
//         <>
//             {/* Loader (progress bar for Excel file upload) */}
//             {Exceldata && (
//                 <div className="progress" role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
//                     <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: `${progress}%` }}></div>
//                 </div>
//             )}

//             {/* Main form container */}
//             <form>
//                 <div className="campaign-form">
//                     <div className="header">
//                         <h5 className='header-text11'>Campaign Name</h5>
//                         <input
//                             className='header-input campaign-name'
//                             type="text"
//                             placeholder="Campaign Name"
//                             value={CompaignId}
//                             onChange={(e) => setCompaignId(e.target.value)}
//                             required
//                         />
//                         <h5 className='header-text'>Templates</h5>
//                         <select
//                             className='header-input template'
//                             value={selectedTemplate}
//                             onChange={(e) => {
//                                 const templateId = e.target.value;
//                                 setSelectedTemplate(templateId);
//                                 handleTemplateSelect(templateId);
//                             }}
//                             required
//                         >
//                             <option value="">Select a Template</option>
//                             {chatsData.map(Templates => (
//                                 <option key={Templates.id} value={Templates.id}>
//                                     {Templates.id}
//                                 </option>
//                             ))}
//                         </select>
//                     </div>

//                     {/* Excel file upload */}
//                     <div className='Excel-div'>
//                         <label className='Excel-lable' htmlFor="file">Excel File</label>
//                         <input
//                             name="Excel File"
//                             id="file"
//                             style={{ display: 'none' }}
//                             type="file"
//                             accept=".xlsx, .xls"
//                             onChange={handleFileUpload}
//                             required
//                         />
//                     </div>

//                     <div className="content">
//                         <div className="left">
//                             <label>Numbers:</label>
//                             <textarea
//                                 className='tera1'
//                                 placeholder="Enter numbers"
//                                 value={numbers}
//                                 onChange={(e) => setNumbers(e.target.value)}
//                                 required
//                             />
//                         </div>
//                         <div className="right">
//                             <div className='message-div'>
//                                 {Exceldata && <h6 className='message-h6'>{currentDisplay}</h6>}
//                                 <label>Message:</label>
//                                 <textarea
//                                     className='tera2'
//                                     placeholder="Enter message"
//                                     value={message}
//                                     onChange={(e) => setMessage(e.target.value)}
//                                     required
//                                 />
//                             </div>

//                             {/* File uploads */}
//                             <div className="uploads">
//                                 <div>
//                                     <label htmlFor="image-file" className="file-label">PDF (Max File Size 2 MB) :→</label>
//                                     <input
//                                         id="image-file"
//                                         type="file"
//                                         accept="image/*"
//                                         style={{ display: 'none' }}
//                                         onChange={(e) => handleFileChange(e, 'image')}
//                                     />
//                                     {imageFile && <span>{imageFile.name}</span>}
//                                 </div>
//                                 <div>
//                                     <label htmlFor="video-file" className="file-label">Video Upload (Max File Size 3 MB) :→</label>
//                                     <input
//                                         id="video-file"
//                                         type="file"
//                                         accept="video/*"
//                                         style={{ display: 'none' }}
//                                         onChange={(e) => handleFileChange(e, 'video')}
//                                     />
//                                     {videoFile && <span>{videoFile.name}</span>}
//                                 </div>
//                                 <div>
//                                     <label htmlFor="pdf-file" className="file-label">Image Upload (Max File Size 3 MB) :→</label>
//                                     <input
//                                         id="pdf-file"
//                                         type="file"
//                                         accept="application/pdf"
//                                         style={{ display: 'none' }}
//                                         onChange={(e) => handleFileChange(e, 'pdf')}
//                                     />
//                                     {pdfFile && <span>{pdfFile.name}</span>}
//                                 </div>
//                             </div>
//                             <div className="actions">
//                                 <button className="send-now" onClick={() => {
//                                     saveCampaignData();
//                                     displayDataOneByOne();
//                                     setExceldata(true);
//                                 }}>
//                                     Send Now
//                                 </button>
//                                 <button className="check-numbers">Check Numbers</button>
//                             </div>                            
//                         </div>
//                     </div>
//                 </div>
//             </form>
//         </>
//     );
// };
// export default CampaignForm;
