import React, { useState } from 'react'; 
import Sidebar from './Sidebar'; 
import Dataj from './Dataj'; 
import './Home.css'
const Home = () => {
    const [activeButton, setActiveButton] = useState(null);

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };

    return (
        <>
        <div className="app"> {/* Main app container */}
            <Sidebar onButtonClick={handleButtonClick} /> {/* Render Sidebar and pass the click handler */}
            <Dataj /> {/* Render Dataj component */}
            <h1 className="content"> {/* Header to display active button content */}
                {activeButton && <div>Showing {activeButton} content...</div>} {/* Conditionally render active button message */}
            </h1>
        </div>
        </>
    );
};
export default Home;
