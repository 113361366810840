import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";  // Import required functions from Firestore

const firebaseConfig = {
    apiKey: "AIzaSyDmQIxkKor2SxXNEWTNfpxuqyzD7gfI_7A",
    authDomain: "vardhman-f1fba.firebaseapp.com",
    projectId: "vardhman-f1fba",
    storageBucket: "vardhman-f1fba.appspot.com",
    messagingSenderId: "769559809832",
    appId: "1:769559809832:web:8e08bc7272b4b1d84e4a1d",
    measurementId: "G-GPST4YRQ3V"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);  // Initialize Firestore

// Export the necessary functions
export { db, collection, getDocs };
