// import React, { useEffect, useState } from 'react';
// import { collection, getDocs, doc, updateDoc, setDoc, deleteDoc } from 'firebase/firestore';
// import { db } from '../firebaseStore';
// import Widget from './Widget';
// import './Datac.css';

// const predefinedData = {
//     weight: '3.25',
//     quality: '22 Ct Hallmark',
//     pcs: 1,
// };

// const Dataj = () => {
//     const [data, setData] = useState([]);
//     const [id, setId] = useState('');
//     const [description, setDescription] = useState('');
//     const [pcs, setPcs] = useState(predefinedData.pcs);
//     const [quality, setQuality] = useState(predefinedData.quality);
//     const [weight, setWeight] = useState(predefinedData.weight);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [selectedItem, setSelectedItem] = useState(null);
//     const [chatsData, setChatsData] = useState([]);
//     const [viewMode, setViewMode] = useState('All'); // View mode state

//     // Fetch data for the main list
//     useEffect(() => {
//         const fetchData = async () => {
//             setLoading(true);
//             const querySnapshot = await getDocs(collection(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'mobv'));
//             const dataList = querySnapshot.docs.map(doc => ({
//                 id: doc.id,
//                 description: doc.data().cm || '',
//                 pcs: doc.data().un,
//                 quality: doc.data().nm,
//                 weight: doc.data().mb,
//                 createdAt: doc.data().dt?.toDate() || '',
//             }));
//             setData(dataList);
//             setLoading(false);
//         };
//         fetchData();
//     }, []);

//     // Fetch chat data
//     const fetchChatsData = async () => {
//         setLoading(true);
//         try {
//             const querySnapshot = await getDocs(collection(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'msgg'));
//             const chatsList = querySnapshot.docs.map(doc => ({
//                 id: doc.id,
//                 comment: doc.data().comment || '',
//                 dt: doc.data().dt?.toDate() || '',
//                 mb: doc.data().mb || 0,
//                 msg: doc.data().msg || '',
//                 po: doc.data().po || '',
//             }));
//             setChatsData(chatsList);
//         } catch (error) {
//             console.error("Error fetching chat data:", error);
//         }
//         setLoading(false);
//     };

//     useEffect(() => {
//         fetchChatsData();
//     }, []);

//     // Reset form to default values
//     const resetForm = () => {
//         setId('');
//         setDescription('');
//         setPcs(predefinedData.pcs);
//         setQuality(predefinedData.quality);
//         setWeight(predefinedData.weight);
//         setSelectedItem(null);
//     };

//     // Add or update data
//     const handleAddOrUpdateData = async () => {
//         if (!id || !description) {
//             alert("ID and Description cannot be empty!");
//             return;
//         }

//         const newItem = {
//             desc: description,
//             pcs,
//             quality,
//             weight,
//             createdAt: new Date().toISOString(),
//         };

//         const docRef = doc(db, 'ornaments', id);

//         if (selectedItem) {
//             await updateDoc(docRef, newItem);
//             setData(prevData => prevData.map(item => (item.id === selectedItem.id ? { id, ...newItem } : item)));
//         } else {
//             await setDoc(docRef, newItem);
//             setData(prevData => [...prevData, { id, ...newItem }]);
//         }
//         resetForm();
//     };

//     // Edit item
//     const handleEditItem = (item) => {
//         setId(item.id);
//         setDescription(item.description);
//         setPcs(item.pcs);
//         setQuality(item.quality);
//         setWeight(item.weight);
//         setSelectedItem(item);
//     };

//     // Delete item
//     const handleDeleteItem = async () => {
//         if (!selectedItem) return;
//         const docRef = doc(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'mobv', selectedItem.id);
//         await deleteDoc(docRef);
//         setData(prevData => prevData.filter(item => item.id !== selectedItem.id));
//         resetForm();
//     };

//     // Dummy function for message sending
//     const handleMessageSend = (message) => {
//         console.log("Message sent:", message);
//         // Add your message sending logic here
//     };

//     // Filter data based on search term and view mode
//     const filteredData = data.filter(item => {
//         const matchesSearch = item.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
//             item.description.toLowerCase().includes(searchTerm.toLowerCase());
//         if (viewMode === 'Unread') {
//             return matchesSearch && item.pcs === 0; // Show only items with pcs (un) = 0
//         }
//         return matchesSearch; // Show all items
//     });

//     return (
//         <>
//             <div className='outer_div_data'>
//                 <div>
//                     <input
//                         type="text"
//                         placeholder="Search..."
//                         value={searchTerm}
//                         onChange={(e) => setSearchTerm(e.target.value)}
//                         className="search-bar"
//                     />
//                     <div className="edit-delete-buttons">
//                         <button className='edit_delete_btn' onClick={() => setViewMode('All')}>All</button>
//                         <button className='edit_delete_btn' onClick={() => setViewMode('Unread')}>Unread</button>
//                         <button className='edit_delete_btn' onClick={handleDeleteItem} disabled={!selectedItem}>Delete</button>
//                         <button className='edit_delete_btn'>Rec</button>
//                     </div>
//                     {loading ? (
//                         <p>Loading...</p>
//                     ) : (
//                         <div className="widgets-container">
//                             {filteredData.map(item => (
//                                 <div
//                                     key={item.id}
//                                     className="widget-item"
//                                     onClick={() => handleEditItem(item)}
//                                 >
//                                     <Widget
//                                         name={item.id}
//                                         description={item.description}
//                                         pcs={item.pcs}
//                                         quality={item.quality}
//                                         weight={item.weight}
//                                         createdAt={item.createdAt}
//                                         onMessageSend={handleMessageSend} // Pass the function if required
//                                     />
//                                 </div>
//                             ))}
//                         </div>
//                     )}
//                 </div>
//             </div>
//             <br />
//             <div className='div_for_details'>
//                 <div className="input-form">
//                     <input
//                         className='Add_data_input'
//                         type="text"
//                         placeholder="Enter ID"
//                         value={id}
//                         onChange={(e) => setId(e.target.value)}
//                         disabled={selectedItem !== null}
//                     />
//                     <input
//                         className='Add_data_input'
//                         type="text"
//                         placeholder="Enter Description"
//                         value={description}
//                         onChange={(e) => setDescription(e.target.value)}
//                     />
//                     <button className='btn_Add_data' onClick={handleAddOrUpdateData}>
//                         {selectedItem ? 'Update Item' : 'Add Item'}
//                     </button>
//                 </div>

//                 {/* Render all chats data */}
//                 <div className='chats-outerdiv-div'>
//                     <h3>Chats Data</h3>
//                     {chatsData.length > 0 ? (
//                         <ul className='chat_ul'>
//                             {chatsData.map(chat => (
//                                 <li className={`chat_li ${chat.po.includes('sn') ? 'chat_li_sn' : ''}`} key={chat.id}>
//                                     <h6 className='chat_dt'><strong>Date:</strong> {chat.dt ? chat.dt.toLocaleString() : 'N/A'}</h6>
//                                     {chat.msg} <br />
//                                 </li>
//                             ))}
//                         </ul>
//                     ) : (
//                         <p>No chats found.</p>
//                     )}
//                 </div>
//             </div>
//         </>
//     );
// };
// export default Dataj;


import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, updateDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebaseStore';
import Widget from './Widget';
import './Datac.css';

const predefinedData = {
    weight: '3.25',
    quality: '22 Ct Hallmark',
    pcs: 1,
};

const Dataj = () => {
    const [data, setData] = useState([]);
    const [id, setId] = useState('');
    const [description, setDescription] = useState('');
    const [pcs, setPcs] = useState(predefinedData.pcs);
    const [quality, setQuality] = useState(predefinedData.quality);
    const [weight, setWeight] = useState(predefinedData.weight);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [chatsData, setChatsData] = useState([]);
    const [viewMode, setViewMode] = useState('All'); // View mode state

    // Fetch data for the main list
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const querySnapshot = await getDocs(collection(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'mobv'));
            const dataList = querySnapshot.docs.map(doc => ({
                id: doc.id,
                description: doc.data().cm || '',
                pcs: doc.data().un,
                quality: doc.data().nm,
                weight: doc.data().mb,
                createdAt: doc.data().dt?.toDate() || '',
            }));
            setData(dataList);
            setLoading(false);
        };
        fetchData();
    }, []);

    const handleDeleteItem = async () => {
        if (!selectedItem) return;
        const docRef = doc(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'mobv', selectedItem.id);
        await deleteDoc(docRef);
        setData(prevData => prevData.filter(item => item.id !== selectedItem.id));
        resetForm();
    };
    // Fetch chat data
    const fetchChatsData = async () => {
        setLoading(true);
        try {
            const querySnapshot = await getDocs(collection(db, 'WhatsApp', 'My Mobile', 'my_mobile', '9294800451', 'msgg'));
            const chatsList = querySnapshot.docs.map(doc => ({
                id: doc.id,
                comment: doc.data().comment || '',
                dt: doc.data().dt?.toDate() || '',
                mb: doc.data().mb || 0,
                msg: doc.data().msg || '',
                po: doc.data().po || '',
            }));
            setChatsData(chatsList);
        } catch (error) {
            console.error("Error fetching chat data:", error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchChatsData();
    }, []);

    // Reset form to default values
    const resetForm = () => {
        setId('');
        setDescription('');
        setPcs(predefinedData.pcs);
        setQuality(predefinedData.quality);
        setWeight(predefinedData.weight);
        setSelectedItem(null);
    };

    // Add or update data
    const handleAddOrUpdateData = async () => {
        if (!id || !description) {
            alert("ID and Description cannot be empty!");
            return;
        }

        const newItem = {
            desc: description,
            pcs,
            quality,
            weight,
            createdAt: new Date().toISOString(),
        };

        const docRef = doc(db, 'ornaments', id);

        if (selectedItem) {
            await updateDoc(docRef, newItem);
            setData(prevData => prevData.map(item => (item.id === selectedItem.id ? { id, ...newItem } : item)));
        } else {
            await setDoc(docRef, newItem);
            setData(prevData => [...prevData, { id, ...newItem }]);
        }
        resetForm();
    };

    // Edit item
    const handleEditItem = (item) => {
        setId(item.id);
        setDescription(item.description);
        setPcs(item.pcs);
        setQuality(item.quality);
        setWeight(item.weight);
        setSelectedItem(item);
    };
    /////////////////////////////////////////////////////////////////////////

    // Dummy function for message sending
    const handleMessageSend = (message) => {
        console.log("Message sent:", message);
        // Add your message sending logic here
    };

    // Filter data based on search term and view mode
    const filteredData = data.filter(item => {
        const matchesSearch = item.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.description.toLowerCase().includes(searchTerm.toLowerCase());
        if (viewMode === 'Unread') {
            return matchesSearch && item.pcs === 0; // Show only items with pcs (un) = 0
        }
        return matchesSearch; // Show all items
    });

    return (
        <>
            <div className='outer_div_data'>
                <div>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-bar"
                    />
                    <div className="edit-delete-buttons">
                        <button className='edit_delete_btn' onClick={() => setViewMode('All')}>All</button>
                        <button className='edit_delete_btn' onClick={() => setViewMode('Unread')}>Unread</button>
                        <button className='edit_delete_btn' onClick={handleDeleteItem} disabled={!selectedItem}>Delete</button>
                        <button className='edit_delete_btn'>Rec</button>
                    </div>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <div className="widgets-container">
                            {filteredData.map(item => (
                                <div
                                    key={item.id}
                                    className="widget-item"
                                    onClick={() => handleEditItem(item)}
                                >
                                    <Widget
                                        name={item.id}
                                        description={item.description}
                                        pcs={item.pcs}
                                        quality={item.quality}
                                        weight={item.weight}
                                        createdAt={item.createdAt}
                                        onMessageSend={handleMessageSend} // Pass the function if required
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <br />
            <div className='div_for_details'>
                {/* Render all chats data */}
                    <h3>Chats Data</h3>
                    {chatsData.length > 0 ? (
                        <ul className='chat_ul'>
                            {chatsData.map(chat => (
                                <li className={`chat_li ${chat.po.includes('sn') ? 'chat_li_sn' : ''}`} key={chat.id}>
                                    <h6 className='chat_dt'><strong>Date:</strong> {chat.dt ? chat.dt.toLocaleString() : 'N/A'}</h6>
                                    {chat.msg} <br />
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No chats found.</p>
                    )}
                </div>
        </>
    );
};
export default Dataj;